import { css, keyframes } from '@emotion/react'

const spin2D = keyframes`
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }

  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
`

export default css`
  animation: ${spin2D} 2s linear infinite;
`
