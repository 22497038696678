import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'

import { router, Link } from '~/lib/router'
import { kebabCase } from 'lodash'

import * as style from './styles'

const MenuItems = ({ categories }) => {
  const [loadingItemId, setLoadingItemId] = useState(null)

  return (
    <div css={style.menuItemsContainer}>
      {
        categories.map(item => {
          const { id, name, image: { large } } = item
          const isLoading = loadingItemId === id

          return (
            <Link
              href={router.routes.services}
              params={{ id, slug: kebabCase(name) }}
              key={`menu_item-${id}`}
              css={style.menuItem(isLoading)}
              onClick={() => setLoadingItemId(id)}
            >
              <img src={large} css={style.menuItemIcon} />
              {name}
            </Link>
          )
        })
      }
    </div>
  )
}

const HomePage = ({ categories }) => {
  return (
    <section css={style.homePage}>
      <MenuItems categories={categories} />
    </section>
  )
}

HomePage.getInitialProps = async (ctx) => {
  const { rootStore: { categoriesStore } } = ctx

  const categories = await categoriesStore.getCategories()

  return {
    categories,
  }
}

export default inject('rootStore')(observer(HomePage))
