import { css } from '@emotion/react'
import { mqMin, mqMax } from '~/styles/mixins/mediaQueries'
import { dropShadow, transition } from '~/styles/constants'
import animationSpin2D from '~/styles/mixins/animations/spin2d'

export const homePage = css`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`

export const menuItemsContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
`

export const menuItem = isLoading => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 2rem;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  cursor: pointer;

  ${mqMax.md} {
    width: 30%;
  }

  ${mqMax.sm} {
    width: 40%;
  }

  > img {
    ${isLoading && animationSpin2D};
  }

  &:hover {
    > img {
      ${dropShadow};
    }
  }
`

export const menuItemIcon = css`
  width: 100%;
  ${transition};

  ${mqMin.xl} {
    max-width: 11rem;
  }

  ${mqMax.xl} {
    max-width: 10rem;
  }

  ${mqMax.lg} {
    max-width: 8rem;
  }

  ${mqMax.md} {
    max-width: 100%;
  }
`
